<template>
  <b-card no-body class="path-benefits w-background-light-gray">
    <b-card-body class="p-0">
      <h3 class="path-benefits__title --rule">
        {{ isAdmin ? "Čo získate" : "Čo získaš" }}
      </h3>

      <div class="d-flex flex-column">
        <div
          v-for="benefit of benefits"
          :key="benefit.description"
          class="path-benefits__benefit d-flex flex-row align-items-center my-2"
        >
          <div class="z-icon-wrapper --middle mr-4 w-background-white">
            <component :is="iconTypeToComponentName(benefit.type)" />
          </div>

          <span class="font-weight-bold">{{ benefit.description }}</span>
        </div>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    benefits: { required: true, type: Array },
  },
  components: {
    "benefit-activities-icon": () =>
      import("/assets/icons/benefitActivities.svg?inline"),
    "benefit-certificate-icon": () =>
      import("/assets/icons/benefitCertificate.svg?inline"),
    "benefit-community-icon": () =>
      import("/assets/icons/benefitCommunity.svg?inline"),
    "benefit-materials-icon": () =>
      import("/assets/icons/benefitMaterials.svg?inline"),
    "benefit-test-icon": () => import("/assets/icons/benefitTest.svg?inline"),
    "benefit-video-icon": () => import("/assets/icons/benefitVideo.svg?inline"),
  },
  computed: {
    ...mapGetters("auth", ["user"]),
    isAdmin() {
      return this.user && this.user.type === "teacher";
    },
  },
  methods: {
    iconTypeToComponentName(type) {
      return `benefit-${type}-icon`;
    },
  },
};
</script>

<style lang="scss" scoped>
.path-benefits {
  padding: 1.875rem 1.875rem;
  border: 1px solid var(--a-color-blue-light);

  .path-benefits__title {
    margin-bottom: 1.875rem;
  }

  .path-benefits__benefit {
    &:first-child {
      margin-top: 0 !important;
    }

    &:last-child {
      margin-bottom: 0 !important;
    }
  }
}

/* TODO: Discuss universal change */
.z-icon-wrapper {
  /* TODO: Refactor, DRY */
  &.--middle {
    flex: 0 0 53px;
    width: 53px;
    height: 53px;
  }

  svg {
    min-height: 30px;
  }
}
</style>
